<template>
    <div class="box-container">
        <div class="box-header">
            <h3 class="label">{{ $t('Intakedate') }}</h3>
            <span class="hint">{{ $t('advSearch.filter.intakeDateHint') }}</span>
        </div>
        <div class="tags-container">
            <Tag
                v-for="tag in months"
                :text="tag.text"
                :key="tag.id"
                :regular="false"
                :active="intakeDates.some(date => date.id === tag.id)"
                :clickable="true"
                @click="addIntakeDate(tag)"
            />
        </div>
    </div>
</template>

<script setup>
import Tag from '../../../general/Tag.vue';
import { useCheckCategory } from '../../../../composables/useCheckCategory';

const props = defineProps({
    preSelectedItems: {
        type: Array,
        default: [],
    },
    isCourses: {
        type: Boolean,
        default: false,
    },
});

const { t } = useI18n();
const { categoryFilterIntakeDates } = useCheckCategory();
const emit = defineEmits(['update:intakeDates']);
const intakeDates = ref([]);

if (props.preSelectedItems.length > 0) {
    intakeDates.value = [...props.preSelectedItems];
} else if (categoryFilterIntakeDates.value.length > 0 && !props.isCourses) {
    intakeDates.value = [...categoryFilterIntakeDates.value];
}

onMounted(() => {
    updateSelectedItems();
});

// Emit updated selected items
const updateSelectedItems = () => {
    emit('update:intakeDates', intakeDates.value);
};

const months = [
    { id: 1, text: t('advSearch.filter.months.January') },
    { id: 2, text: t('advSearch.filter.months.February') },
    { id: 3, text: t('advSearch.filter.months.March') },
    { id: 4, text: t('advSearch.filter.months.April') },
    { id: 5, text: t('advSearch.filter.months.May') },
    { id: 6, text: t('advSearch.filter.months.June') },
    { id: 7, text: t('advSearch.filter.months.July') },
    { id: 8, text: t('advSearch.filter.months.August') },
    { id: 9, text: t('advSearch.filter.months.September') },
    { id: 10, text: t('advSearch.filter.months.October') },
    { id: 11, text: t('advSearch.filter.months.November') },
    { id: 12, text: t('advSearch.filter.months.December') },
];

const addIntakeDate = date => {
    const index = intakeDates.value.findIndex(existingDate => existingDate.id === date.id);
    if (index === -1) {
        intakeDates.value.push({ id: date.id, text: date.text, type: 'date' });
    } else {
        intakeDates.value.splice(index, 1);
    }
    updateSelectedItems();
};
</script>

<style lang="scss">
@import 'libs/shared/assets/scss/main.scss';
.box-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .box-header {
        display: flex;
        flex-direction: column;
        .label {
            font-size: toRem(16);
            color: $dark-gray;
            margin-bottom: toRem(7);
        }
        .hint {
            font-size: toRem(14);
            color: $gray-10;
        }
    }
    .tags-container {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem 0.5rem;
    }
}
</style>
